import React, { Component } from "react";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.option = {
      autoplay: this.props?.autoplay || true,
      responsive: this.props?.responsive || true,
      controls: this.props?.controls || true,
      fluid: this.props?.fluid || true,
      muted: this.props?.muted || true,
      nativeControlsForTouch: true, // 啟用手機原生控制
      sources:[{src: this.props?.src || "", type: "application/x-mpegURL"}],
      html5: {
        vhs: {
          overrideNative: !window.videojs.browser.IS_SAFARI, // 讓 video.js 處理 HLS（非 Safari）
        },
      },
    }
  }

  componentDidMount() {
    const videoNode = this.player.current;

    this.video = window.videojs(videoNode, this.option, () => {
      window.videojs.log('onPlayerReady', this);
    });

    this.video.ready(() => {
      console.log("Video.js player is ready");
    });
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.dispose();
    }
  }

  render() {
    return (
      <div data-vjs-player>
        <video ref={this.player} data-setup="{}" className="video-js vjs-big-play-centered" autoplay muted playsinline controls />
      </div>
    );
  }
}

export default VideoPlayer;